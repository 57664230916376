@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
    @font-face {
      font-family: gotham;
      font-weight: 400;
      src: url(../fonts/Gotham-Book.otf) format("otf");
    }
    /* @font-face {
      font-family: Proxima Nova;
      font-weight: 500;
      src: url(/fonts/proxima-nova/500-medium.woff) format("woff");
    } */
  }
  
  .scrolled-nav {
    height: 90px !important;
    line-height: 5px !important;
    background-color: #00023B;
    padding: 5px 0;
    scroll-behavior: smooth;
  }
  .scrolled-nav .icon {
    background-image: url(../img/logos/image.png);
    height: 50px;
  }
  
  /* .scrolled-nav .logo {
    background-image: url(../img/logos/text.png);
    height: 20px;
  } */
  
  .scrolled-nav .logo-text {
    display: none;
  }
  
  .owl-carousel {
    z-index: -1;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    @apply bg-tree-poppy-600;
    border-radius: 50%;
    display: inline-block;
  }
  
  .dot-orange {
    @apply bg-tree-poppy-500;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .dot-orange-deep {
    @apply bg-tree-poppy-600;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  
  