*, :before, :after {
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"] {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: Poppins;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

@font-face {
  font-family: gotham;
  font-weight: 400;
  src: url("Gotham-Book.fe034cb5.otf") format("otf");
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.z-0 {
  z-index: 0;
}

.z-50 {
  z-index: 50;
}

.m-1 {
  margin: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-28 {
  height: 7rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-28 {
  width: 7rem;
}

.w-48 {
  width: 12rem;
}

.w-72 {
  width: 18rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -.25rem;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.bg-tree-poppy-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 151, 21, var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 2, 59, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-header {
  background-image: url("header.26230918.jpg");
}

.bg-partners {
  background-image: url("products-banner.d2b4885d.png");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: Poppins;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.leading-loose {
  line-height: 2;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration: underline;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.scrolled-nav {
  scroll-behavior: smooth;
  background-color: #00023b;
  padding: 5px 0;
  height: 90px !important;
  line-height: 5px !important;
}

.scrolled-nav .icon {
  height: 50px;
  background-image: url("image.7c2a0f71.png");
}

.scrolled-nav .logo-text {
  display: none;
}

.owl-carousel {
  z-index: -1;
}

.dot {
  height: 10px;
  width: 10px;
  --tw-bg-opacity: 1;
  background-color: rgba(221, 125, 2, var(--tw-bg-opacity));
  border-radius: 50%;
  display: inline-block;
}

.dot-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 151, 21, var(--tw-bg-opacity));
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-orange-deep {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 125, 2, var(--tw-bg-opacity));
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

@media (min-width: 640px) {
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-96 {
    height: 24rem;
  }

  .md\:h-128 {
    height: 32rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:pt-3 {
    padding-top: .75rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-3 {
    padding-bottom: .75rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:tracking-normal {
    letter-spacing: 0;
  }
}

/*# sourceMappingURL=index.9da2e220.css.map */
